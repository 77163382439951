
@media screen and (max-width: 810px) {
  
  .table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .table tr {
    display: block;
    margin-bottom: .625em;
  }
  
  .table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 1em;
    text-align: right;
  }
  
  .table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a .table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .table td:last-child {
    border-bottom: 0;
  }
}


@media screen and (max-width: 1024px) {
  
  .parent thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .parent tr {
    display: block;
    margin-bottom: .625em;
  }
  
  .parent td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 1em;
    text-align: right;
  }
  
  .parent td::before {
    /*
    * aria-label has no advantage, it won't be read inside a .parent
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .parent td:last-child {
    border-bottom: 0;
  }
}
