.class
{
  position: fixed;
  top:0;
  right:0;
  min-width: 50vw;
  pointer-events: none;
}

@keyframes fadeIn 
{
  0% 
  {
    opacity: 0;
  }
  
  100% 
  {
    opacity: 1;
  }
}

@keyframes fadeOut 
{
  0% 
  {      
    opacity: 1;
  }

  100% 
  {
    opacity: 0;
  }
}
