@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Jost", "Poppins", sans-serif;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background-color: #fe9b7f;
}

body::-webkit-scrollbar-thumb {
  background-color: #d12608;
  border: 2px solid #fe1b1f;
}

input[type="checkbox"] {
  appearance: auto;
  width: 2em;
  opacity: 1;
}

input {
  appearance: none;
  background: none;
  outline: none;
}
